import React from 'react';
import Article from 'components/help/helpArticle';
import Image from 'components/help/helpImage';

import imgQS from 'img/help/qs.png';
import imgConsole from 'img/help/console.png';

const Content = () => {
  return (
    <div>
      <p>
        To validate LiveSession script installation on your website, simply add{' '}
        <strong>__ls_validation</strong> query-string parameter in your web browser URL:
      </p>
      <Image src={imgQS} alt="Checking script" title="Add query string in URL" />
      <p>
        If your code was installed correctly, you should see this confirmation box on the top left
        of your screen:
      </p>
      <Image
        src={imgConsole}
        alt="Checking script"
        title="Screen of correctly installed LiveSession"
      />
    </div>
  );
};

const related = [
  {
    link: '/help/how-to-install-livesession-script',
    title: 'How to install LiveSession script?',
    description: 'Check how can you install LiveSession on any website.',
  },
];

export const frontmatter = {
  metaTitle: 'How to check if my tracking script works?',
  metaDescription: 'Check if your tracking code is installed correctly',
  canonical: '/help/how-to-check-if-my-tracking-script-works/',
};

const Wrapped = Article(Content);

export default () => (
  <Wrapped related={related} section="Developer" title={frontmatter.metaTitle} {...frontmatter} />
);
